<template>
  <v-container>
    <div v-if="currentVehicle">
      <vehicle-summary :vehicle="currentVehicle" />
    </div>
    <div v-else>loading...</div>
  </v-container>
</template>

<script>
import VehicleSummary from '../../components/vehicleTracker/VehicleTrackerSummary.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'VehicleTrackerSummary',
  components: {
    VehicleSummary
  },
  data() {
    return {
      links: [
        {
          route: 'VehicleTrackerList',
          color: 'red',
          title: 'Vehicle List'
        },
        {
          route: 'VehicleTrackerLog',
          color: 'red',
          title: 'Logs'
        },
        {
          route: 'VehicleTrackerAddRecord',
          color: 'red',
          title: 'Add Record'
        }
      ]
    }
  },
  computed: {
    ...mapState('vehicle', ['vehicles']),
    ...mapGetters('vehicle', ['vehicle']),
    currentVehicle() {
      return this.vehicle(this.$route.params.vehicle)[0]
    },
    fontColor() {
      const color = this.currentVehicle ? this.currentVehicle.color.split(' ') : ''
      if (color.length > 1) {
        if (color[1].startsWith('dark')) {
          return 'white--text'
          // return color[0] + ' ' + color[1] + ' white--text
        } else {
          return 'black--text'
        }
      } else {
        return color
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles']),
    getData() {
      if (this.vehicles.length === 0) {
        this.fetchVehicles()
      }
    }
  }
}
</script>

<style></style>
